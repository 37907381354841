






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import EntityType from '@/utils/enums/EntityType';
import { Getter, namespace, State } from 'vuex-class';
import { Data } from '@/utils/types/WidgetData';
import TableListMapping from '@/utils/widgets/data/TableListMapping';
import PaginationComponent from '@/components/PaginationComponent.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { format, getUnixTime } from 'date-fns';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import Product from '@/models/graphql/Product';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import IllustrationType from '@/utils/enums/IllustrationType';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import ProductEditModal from '@/components/modals/ProductEditModal.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import Deal from '@/models/graphql/Deal';
import Handout from '@/models/graphql/Handout';
import HandoutEditModal from '@/components/modals/HandoutEditModal.vue';
import PromotionEditModal from '@/components/modals/PromotionEditModal.vue';
import FileType from '@/utils/enums/FileType';
import DropdownMenu from '@/components/DropdownMenu.vue';
import DropdownMenuItem from '@/components/DropdownMenuItem.vue';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import FileResource from '@/models/graphql/FileResource';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import ClipboardHelper from '@/utils/helpers/ClipboardHelper';
import MemberInviteModal from '@/components/modals/member-invite/MemberInviteModal.vue';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import Role from '@/models/graphql/Role';
import Exhibitor from '@/models/graphql/Exhibitor';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import StatusOptions from '@/components/company-cms/StatusOptions';
import MenuItems from '@/components/company-cms/MenuItems';
import HeaderMenuItems from '@/components/company-cms/HeaderMenuItems';
import CmsTableHeaders from '@/components/company-cms/CmsTableHeaders';
import CmsTableConfirmModals from '@/components/company-cms/CmsTableConfirmModals.vue';
import CompanyRoleEnum from '@/utils/enums/CompanyRoleEnum';
import LargeProduct from '@/models/graphql/LargeProduct';
import LargeProductAddModal from '@/components/modals/LargeProductAddModal.vue';
import BoatProductEditModal from '@/components/modals/BoatProductEditModal.vue';
import ExtraPropertySection from '@/models/graphql/ExtraPropertySection';
import Booth from '@/models/graphql/Booth';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import PermissionTypes from '@/utils/enums/cms/PermissionTypes';
import LocaleModel from '@/models/LocaleModel';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import CommunityUser from '@/models/graphql/CommunityUser';
import ExhibitorVideo from '@/models/graphql/ExhibitorVideo';
import ExhibitorVideoEditModal from '@/components/modals/ExhibitorVideoEditModal.vue';
import Order from '@/models/graphql/Order';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import PermissionsManagerModal from '@/components/modals/PermissionsManagerModal.vue';
import { BasicTypes } from '@/utils/types/BasicTypes';
import Plyr from 'plyr';
import VideoComponent from '@/components/VideoComponent.vue';

const exhibitorStore = namespace('ExhibitorStore');
const productStore = namespace('ProductStore');
const largeProductStore = namespace('LargeProductStore');
const dealStore = namespace('DealStore');
const handoutStore = namespace('HandoutStore');
const companyUserRoleStore = namespace('CompanyUserRoleStore');
const paginationStore = namespace('WidgetPaginatedStore');
const toastStore = namespace('ToastStore');
const categoryStore = namespace('CategoryStore');
const exhibitorVideoStore = namespace('ExhibitorVideoStore');
const extraPropertySectionStore = namespace('ExtraPropertySectionStore');
const salesPackageLimitStore = namespace('SalesPackageLimitStore');
const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    VideoComponent,
    PermissionsManagerModal,
    ExhibitorVideoEditModal,
    BoatProductEditModal,
    LargeProductAddModal,
    CmsTableConfirmModals,
    MemberInviteModal,
    PillWidget,
    AvatarSoloWidget,
    DropdownMenuItem,
    DropdownMenu,
    PromotionEditModal,
    HandoutEditModal,
    FontAwesomeComponent,
    ProductEditModal,
    IllustrationComponent,
    ButtonComponent,
    ConfirmModal,
    InputSearchComponent,
    PillComponent,
    PaginationComponent,
  },
})
/* eslint-disable @typescript-eslint/camelcase,max-len,no-restricted-globals,no-underscore-dangle */
export default class CmsTableComponent extends mixins(VueBaseWidget, VueBaseNotify) {
  @Prop({
    required: false,
    default: null,
  })
  protected handoutSubType!: string;

  @State
  protected unifyDomain!: string;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  @State
  private dateLocale!: Locale;

  @paginationStore.Action
  private loadCount!: (payload: { query: string; variables: object }) => Promise<void>;

  @paginationStore.Action
  private loadTotalCount!: (payload: {
    query: string;
    variables: object;
  }) => Promise<void>;

  @paginationStore.Action
  private loadPaginated!: (payload: {
    query: string;
    variables: object;
  }) => Promise<void>;

  @paginationStore.Getter
  private fetchPaginated!: Data[];

  @paginationStore.Getter
  private fetchCount!: number;

  @paginationStore.Getter
  private fetchTotalCount!: number;

  @productStore.Action
  private publishProduct!: (payload: {
    uid: string;
    publicationTime: string;
  }) => Promise<void | Product | undefined>;

  @productStore.Action
  private unpublishProduct!: (payload: {
    uid: string;
    endPublicationTime: string;
  }) => Promise<Product | undefined>;

  @productStore.Action
  private deleteProduct!: (uid: string) => Promise<boolean>;

  @exhibitorVideoStore.Action('delete')
  private deleteExhibitorVideo!: (uid: string) => Promise<boolean>;

  @productStore.Getter('getAllPromisesLoading')
  private productPromisesLoading!: boolean;

  @dealStore.Getter('getPromotionLinksLoading')
  private promotionLinksLoading!: boolean;

  @largeProductStore.Action
  private publishLargeProduct!: (payload: {
    uid: string;
    publicationTime: string;
  }) => Promise<LargeProduct | undefined>;

  @largeProductStore.Action
  private unpublishLargeProduct!: (payload: {
    uid: string;
    endPublicationTime: string;
  }) => Promise<LargeProduct | undefined>;

  @largeProductStore.Action
  private deleteLargeProduct!: (uid: string) => Promise<boolean>;

  @largeProductStore.Getter('getAllPromisesLoading')
  private largeProductPromisesLoading!: boolean;

  @dealStore.Action
  private publishDeal!: (payload: {
    uid: string;
    startTime: string;
  }) => Promise<Deal | undefined>;

  @dealStore.Action
  private unpublishDeal!: (payload: {
    uid: string;
    endTime: string;
  }) => Promise<Deal | undefined>;

  @dealStore.Action
  private deleteDeal!: (uid: string) => Promise<boolean>;

  @handoutStore.Action
  private deleteHandout!: (uid: string) => Promise<boolean>;

  @companyUserRoleStore.Action
  private updateCompanyUserRole!: (payload: {
    companyUserRoleId: string;
    roleId: string;
  }) => Promise<void | CompanyUserRole | undefined>;

  @companyUserRoleStore.Action
  private updateCompanyUserRoleState!: (payload: {
    uid: string;
    state: CompanyUserRoleStatusType;
  }) => Promise<void | CompanyUserRole | undefined>;

  @companyUserRoleStore.Action
  private getRoleByCode!: (code: string) => Promise<void | Role | undefined>;

  @companyUserRoleStore.Getter
  private saveLoading!: boolean;

  @categoryStore.Action
  private loadProductCategoryForCmsTable!: (payload: {
    id?: string;
    schemaCode: string;
  }) => Promise<void>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @extraPropertySectionStore.Action
  private loadExtraPropertySections!: (payload: {
    filter: object; params: Record<string, BasicTypes>;
  }) => Promise<ExtraPropertySection[]>;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string; type?: string | null })
    => Promise<{ type: string; limit: number; code: number }[]>;

  @permissionManagerStore.Getter
  private canManageCompanyContent!: (companyUid: string) => boolean;

  private extraPropertySections: ExtraPropertySection[] = [];

  @Prop({ required: true })
  private entityDetailId!: string;

  @Prop({
    required: true,
    default: EntityType.PRODUCT,
  })
  private entityType!: string;

  @Prop({
    required: false,
    default: true,
  })
  private newEntityEnable!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private searchEnable!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private allowManufacture!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private allowExtraProperties!: boolean;

  @Prop({ required: false })
  private productFields!: string;

  @Prop({ required: false })
  private boatProductsMandatoryFields!: string;

  @Prop({ required: false })
  private productCategory!: string;

  @State
  private readonly locales!: LocaleModel[];

  private doCount = false;

  private orderBy = 'name_desc';

  private isLoading = true;

  private graphQlQuery = '';

  private graphQlCount = '';

  private list: Data[] = [];

  private selectedList: Data[] = [];

  private count = 0;

  private first = 0;

  private offset = 0;

  private page = 1;

  private graphQlVariables: Data = {};

  private overrideFilter: Data = {};

  private queryFilter: Record<string, object | string> = {};

  private itemHovered: Data | null = null;

  private searchValue = '';

  private publishedItemsCount = 0;

  private unpublishedItemsCount = 0;

  private deletedItemsCount = 0;

  private selectedItem:
    | Product
    | Deal
    | Handout
    | Data
    | CommunityUser
    | LargeProduct
    | ExhibitorVideo
    | null = null;

  private selectedHandout: Handout | Data | null = null;

  private FileResourceHelper = FileResourceHelper;

  private IllustrationType = IllustrationType;

  private EntityType = EntityType;

  private showMenu = true;

  private statusOptions = StatusOptions;

  private menuItems = MenuItems;

  private companyRole = CompanyRoleEnum;

  private nbToAddLeft: number | undefined = -1;

  private totalAuthorized: number | undefined = 0;

  private totalCount = 0;

  private readonly PERMISSION_MENU_NAME = 'permissions';

  private FeatureKeys = FeatureKeys;

  private PermissionTypes = PermissionTypes;

  private entityHandoutLimit = -1;

  private isMeetingFeatureEnabled = true;

  private isMessageFeatureEnabled = true;

  private boatCanCreate = false;

  private boatCanEdit = false;

  private boatCanEditBooth = false;

  private boatCanImportFromNMMA = false;

  private boatFields: string[] = [];

  private boatExtraPropertyKeys: string[] = [];

  private player: Plyr | null = null;

  private showVideoPopup = false;

  private TableListMapping: Record<string,
    Record<string, object | string>> = TableListMapping;

  private get booths(): Booth[] {
    if (
      this.adminPanelExhibitor.boothsOfEdition
      && this.adminPanelExhibitor.boothsOfEdition.length > 0
    ) {
      return this.adminPanelExhibitor.boothsOfEdition;
    }
    return [];
  }

  private get availableLocales(): string[] {
    return this.locales.map((l) => l.locale as string);
  }

  private get canShowAddBtn(): boolean {
    switch (this.entityType) {
      case EntityType.BOAT_PRODUCT:
        return this.boatCanCreate;
      case EntityType.ORDER:
        return false;
      case EntityType.USER:
        return this.newEntityEnable
          && this.canDisplayInviteMemberButton
          && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION)
          && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION).enabled;
      default:
        return this.newEntityEnable;
    }
  }

  private get productImageLimit(): number {
    if (this.adminPanelExhibitor && !this.isNewLimitCalculationFeatureEnabled) {
      switch (this.entityType) {
        case EntityType.PRODUCT:
          return this.adminPanelExhibitor._effectiveLimitNumImagePerProduct ?? -1;
        case EntityType.BOAT_PRODUCT:
        case EntityType.LARGE_PRODUCT:
          return this.adminPanelExhibitor._effectiveLimitNumImagePerLargeProduct ?? -1;
        default:
          return -1;
      }
    }
    return -1;
  }

  private get isNewLimitCalculationFeatureEnabled(): boolean {
    const salesServiceFeature = this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE);
    return !!salesServiceFeature && salesServiceFeature.enabled;
  }

  private get canAdd(): boolean {
    if (this.isNewLimitCalculationFeatureEnabled) {
      if (!this.newEntityEnable) {
        return false;
      }
      if (!this.hasLimit) {
        return true;
      }
      if (this.totalAuthorized === -1) {
        return true;
      }
      if (this.totalAuthorized === 0) {
        return false;
      }
      return this.nbToAddLeft ? this.nbToAddLeft > 0 : false;
    }
    return this.canAddWithDefaultLimit;
  }

  private get hasChatFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_CHAT_FEATURE) && this.isMessageFeatureEnabled;
  }

  private get hasPostCommentFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_POST_ENABLE_COMMENT);
  }

  private get hasCompanyMeetingFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING) && this.isMeetingFeatureEnabled;
  }

  private get canManageContent(): boolean {
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
      case EntityType.DEAL:
      case EntityType.EXHIBITOR_VIDEO:
      case EntityType.HANDOUT:
        return this.canManageCompanyContent(this.entityDetailId);
      default:
        return true;
    }
  }

  private get hasLimit(): boolean {
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
      case EntityType.DEAL:
      case EntityType.USER:
      case EntityType.EXHIBITOR_VIDEO:
      case EntityType.HANDOUT:
        return this.totalAuthorized !== -1;
      default:
        return false;
    }
  }

  private get canAddWithDefaultLimit(): boolean {
    if (!this.newEntityEnable) {
      return false;
    }
    if (!this.hasDefaultLimit()) {
      return true;
    }
    if (this.totalAuthorized === -1) {
      return true;
    }
    return this.nbToAddLeft ? this.nbToAddLeft > 0 : false;
  }

  private get getQuantity(): string {
    let result = this.$tc(`admin-panel.count.${this.entityType.toLowerCase()}`, 0, { count: this.totalCount });
    if (this.hasLimit && this.nbToAddLeft !== -1 && this.totalAuthorized !== 0) {
      result = `${this.totalCount} / ${this.totalAuthorized}`;
    }
    return result;
  }

  private get canDisplayInviteMemberButton(): boolean {
    return this.entityType === EntityType.USER
    && this.authUser.companyRoles ? !!this.authUser.companyRoles
        .find((cr) => cr.company?.uid === this.entityDetailId
        && (cr.role.code === CompanyRoleEnum.OWNER || !!cr.manageCompanyUserRoles)) : false;
  }

  private get getVideoSource(): string {
    const selectedItem = (this.selectedItem as unknown as ExhibitorVideo);
    return selectedItem.url || '';
  }

  applySearch(payload?: { query: string }): void {
    const { query } = this.$route;
    if (payload && payload.query && payload.query.length > 0) {
      Object.assign(query, { search: payload?.query });
    } else {
      delete query.search;
    }
    const route = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query,
    }).href;
    window.history.pushState({ search: payload?.query }, '', route);
    this.searchValue = this.$route.query.search as string;
    this.loadBaseData();
  }

  created(): void {
    if (this.entityType !== EntityType.SPEAKER) {
      this.notifyEvents = [NotificationEventType.COMPANY_INVITE];
    }
  }

  mounted(): void {
    this.searchValue = this.$route.query.search as string;
    this.loadBaseData();
  }

  protected notificationCallback(event: SubscriptionEvent): void {
    if (this.entityType === EntityType.USER
      && event.type === NotificationEventType.COMPANY_INVITE) {
      const notification = Notification.hydrate(event.data);
      if (notification && notification.initiator) {
        const foundUserIndex = this.list.findIndex((user) => notification.initiator
          && user
          && notification.initiator.uid === user.uid);
        if (foundUserIndex > -1) {
          if (notification.triggered) {
            if ((notification.triggered as {
              __typename: string;
            }).__typename === 'CompanyUserRole') {
              const companyRole = notification.triggered as CompanyUserRole;
              if (companyRole
                && companyRole.state
                && [CompanyUserRoleStatusType.ACCEPTED, CompanyUserRoleStatusType.INVITED].includes(companyRole.state)) {
                const user = (this.list[foundUserIndex] as unknown as CommunityUser);
                if (companyRole && user) {
                  const { state } = companyRole;
                  this.$set(user.roleInCompany, 'state', state);
                }
              } else {
                this.list.splice(foundUserIndex, 1);
                this.deletedItemsCount += 1;
              }
            }
          } else {
            this.list.splice(foundUserIndex, 1);
            this.deletedItemsCount += 1;
          }
        }
      }
    }
  }

  private videoUploading(video: Data): void {
    this.$bvModal.hide('exhibitor-video-modal');
    const videoIndex = this.list.findIndex((v) => v.uid === video.uid);
    if (videoIndex > -1) {
      this.list.splice(videoIndex, 1, video);
    } else {
      this.list.push(video);
    }
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }

  @Watch('selectedList')
  private headerMenuItems(): object {
    return HeaderMenuItems.getHeaderMenuItems(this.selectedList, this.entityType);
  }

  @Watch('breakpoint.value')
  private headers(): object {
    return CmsTableHeaders.getHeaders(this.breakpoint);
  }

  private serialiseHeader(headerItems: { name: string; translation: string; class: string }[]): {
    name: string; translation: string; class: string;
  }[] {
    if (this.entityType === EntityType.USER) {
      return headerItems.filter((headerItem) => {
        switch (headerItem.name.toLowerCase()) {
          case this.PERMISSION_MENU_NAME:
            return this.hasCompanyMeetingFeature || this.hasChatFeature || this.hasPostCommentFeature;
          default:
            return true;
        }
      });
    }
    return headerItems;
  }

  private canDisplayPermissionMenuForMember(user: CommunityUser): boolean {
    return this.entityType === EntityType.USER
      && user
      && user.roleInCompany.role.code !== CompanyRoleEnum.OWNER
      && (!!this.authUser.companyRoles
        .find((cr) => cr.company?.uid === this.entityDetailId
            && (cr.role.code === CompanyRoleEnum.OWNER || !!cr.manageCompanyUserRoles))
        || this.authUser.uid === user.uid);
  }

  private canDisplayDeleteMenuForMember(user: CommunityUser): boolean {
    return this.entityType === EntityType.USER
      && user
      && user.uid !== this.authUser.uid
      && user.roleInCompany.role.code !== CompanyRoleEnum.OWNER
      && !!this.authUser.companyRoles
        .find((cr) => cr.company?.uid === this.entityDetailId
          && (cr.role.code === CompanyRoleEnum.OWNER || !!cr.manageCompanyUserRoles));
  }

  private loadData(): void {
    this.isLoading = true;
    this.graphQlVariables = {};
    let order = '';
    if (this.orderBy && this.orderBy.length > 0) {
      if (this.entityType !== EntityType.USER && this.entityType !== EntityType.ORDER) {
        order = `orderBy: [${this.orderBy.split(',')}]`;
      }
    }
    if (this.first > 0) {
      this.graphQlQuery = this.graphQlQuery.replaceAll(
        '%declaration%',
        '$first: Int, $offset: Int, ',
      );
      let useDeclaration = 'first: $first, offset: $offset, ';
      if (order && order.length > 0) {
        useDeclaration = `${order}, ${useDeclaration}`;
      }
      if (this.$route.query
        && 'search' in this.$route.query
        && this.$route.query.search.length > 0) {
        Object.assign(this.graphQlVariables, { _fts: this.$route.query.search });
      } else {
        delete this.graphQlVariables._fts;
      }
      this.graphQlQuery = this.graphQlQuery.replaceAll('%useDeclaration%', useDeclaration);
      Object.assign(this.graphQlVariables, { first: this.first });
      Object.assign(this.graphQlVariables, { offset: this.offset });
    } else {
      this.graphQlQuery = this.graphQlQuery.replaceAll('%declaration%', '');
      this.graphQlQuery = this.graphQlQuery.replaceAll('%useDeclaration%', order);
    }
    this.graphQlQuery = this.graphQlQuery.replaceAll('%companyUid%', this.entityDetailId);
    const filter = {
      ...(this.queryFilter as Record<string, object>).filter,
      ...this.overrideFilter,
    };
    Object.assign(this.queryFilter.filter, filter);
    Object.assign(this.graphQlVariables, { ...this.queryFilter });

    if (this.doCount) {
      this.doCount = false;
      this.loadCount({
        variables: this.graphQlVariables,
        query: this.graphQlCount,
      })
        .then(() => {
          this.count = this.fetchCount;
        });
      const noFtsGraphQlVariables = { ...this.graphQlVariables };
      delete noFtsGraphQlVariables._fts;
      this.loadTotalCount({
        variables: noFtsGraphQlVariables,
        query: this.graphQlCount,
      })
        .then(() => {
          this.totalCount = this.fetchTotalCount;
        });
    }
    this.loadPaginated({
      variables: this.graphQlVariables,
      query: this.graphQlQuery,
    })
      .then(() => {
        this.list = this.fetchPaginated;
        this.isLoading = false;
      });
    if ((this.entityType === EntityType.PRODUCT
        || this.entityType === EntityType.LARGE_PRODUCT
        || this.entityType === EntityType.BOAT_PRODUCT)
      && this.community.code) {
      this.loadProductCategoryForCmsTable({
        id: this.productCategory,
        schemaCode: this.community.code,
      });
    }
  }

  @Watch('entityDetailId')
  private loadBaseData(): void {
    if (this.entityDetailId) {
      if (this.isNewLimitCalculationFeatureEnabled) {
        this.filterSlotLimits();
        this.loadBoatSlots();
      }
      if (this.entityType === EntityType.USER) {
        if (!this.productCategory) {
          this.applyFilter({
            isFilterActive: 0,
            filters: {
              filter: {
                _inCompany: this.entityDetailId,
                state_not_in: [
                  CompanyUserRoleStatusType.DECLINED,
                  CompanyUserRoleStatusType.CANCELLED,
                  CompanyUserRoleStatusType.INVITE_CANCELLED,
                ],
              },
            },
          });
        } else {
          this.applyFilter({
            isFilterActive: 0,
            filters: {
              filter: {
                _inCompany: this.entityDetailId,
                state_not_in: [
                  CompanyUserRoleStatusType.DECLINED,
                  CompanyUserRoleStatusType.CANCELLED,
                  CompanyUserRoleStatusType.INVITE_CANCELLED,
                ],
                categories: { id: this.productCategory },
              },
            },
          });
        }
      } else if (this.entityType === EntityType.SPEAKER) {
        this.applyFilter({
          isFilterActive: 0,
          filters: {
            filter: {
              speaker: { uid: this.entityDetailId },
              handoutFileResource_not: null,
            },
          },
        });
      } else if (!this.productCategory) {
        if (this.entityType === EntityType.HANDOUT && this.handoutSubType) {
          this.applyFilter({
            isFilterActive: 0,
            filters: {
              filter: {
                exhibitor: { uid: this.entityDetailId },
                type: this.handoutSubType,
              },
            },
          });
        } else {
          this.applyFilter({
            isFilterActive: 0,
            filters: {
              filter: {
                exhibitor: { uid: this.entityDetailId },
              },
            },
          });
        }
      } else {
        this.applyFilter({
          isFilterActive: 0,
          filters: {
            filter: {
              exhibitor: { uid: this.entityDetailId },
              categories: { id: this.productCategory },
            },
          },
        });
      }
      this.first = 16;
      if (this.entityType === EntityType.SPEAKER) {
        this.graphQlQuery = this.TableListMapping.handout.query as string;
        this.graphQlCount = this.TableListMapping.handout.count as string;
      } else {
        this.graphQlQuery = this.TableListMapping[this.entityType.toLowerCase()].query as string;
        this.graphQlCount = this.TableListMapping[this.entityType.toLowerCase()].count as string;
      }

      this.graphQlQuery = this.graphQlQuery.replace(/(\r\n|\n|\r)/gm, '');
      this.graphQlQuery = this.graphQlQuery.replace(/\s+/g, ' ')
        .trim();
      if (this.$route.query
        && 'search' in this.$route.query
        && this.$route.query.search.length > 0) {
        this.graphQlQuery = this.graphQlQuery.replaceAll(
          '%searchQuery%',
          `"${this.searchValue}"`,
        );
      }

      this.graphQlCount = this.graphQlCount.replace(/(\r\n|\n|\r)/gm, '');
      this.graphQlCount = this.graphQlCount.replace(/\s+/g, ' ')
        .trim();

      if (this.$route.query.page) {
        this.page = Math.abs(parseInt(this.$route.query.page as string, 10));
      }
      this.offset = this.first * (this.page - 1);
      this.doCount = true;
      this.loadData();
    }
  }

  private applyFilter(payload: {
    isFilterActive: number;
    filters: Record<string, object | string>;
  }): void {
    this.queryFilter = payload.filters;
    this.doCount = true;
    if (payload.isFilterActive > 0) {
      this.offset = 0;
    }
  }

  private onPageChanged(page: number): void {
    window.scrollTo({ top: 0 });
    this.offset = this.first * (page - 1);
    this.page = page;
    this.loadData();
  }

  private isSelected(item: Data): boolean {
    const selected = this.selectedList.filter((selectedItem: Data) => selectedItem.id === item.id);
    return !!selected[0];
  }

  private onCheckBoxClick(item: Data): void {
    if (this.isSelected(item)) {
      const index = this.selectedList.findIndex((selectedItem) => selectedItem.id === item.id);
      if (index >= 0) {
        this.selectedList.splice(index, 1);
      }
    } else {
      this.selectedList.push(item);
    }
  }

  private onMainCheckBoxClick(): void {
    if (this.selectedList.length > 0) {
      this.selectedList = [];
    } else {
      this.selectedList = this.list;
    }
  }

  private communityUserRole(item: Data): { role: string; variant: string } {
    const user = (item as unknown) as CommunityUser;
    const companyUserRole = user.roleInCompany;
    if (companyUserRole && companyUserRole.role) {
      let variant = '';
      let role = '';
      if (companyUserRole.state === CompanyUserRoleStatusType.INVITED
        || companyUserRole.state === CompanyUserRoleStatusType.TO_BE_INVITED) {
        role = this.$t('admin-panel.status.pending')
          .toString();
        variant = 'yellow';
      } else if (companyUserRole.role.code === CompanyRoleEnum.OWNER
        && companyUserRole.state === CompanyUserRoleStatusType.ACCEPTED) {
        role = this.$t('admin-panel.status.owner')
          .toString();
        variant = 'black';
      }
      return {
        role,
        variant,
      };
    }
    return {
      role: '',
      variant: '',
    };
  }

  private getItemStatus(item: Data): object {
    let result = {};
    let publicationTime: string | number | object | null = null;
    let endPublicationTime: string | number | true | object | null = null;
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        publicationTime = item.publicationTime ? getUnixTime(new Date(item.publicationTime as unknown as string)) : null;
        endPublicationTime = item.endPublicationTime ? getUnixTime(new Date(item.endPublicationTime as unknown as string)) : null;
        break;

      case EntityType.DEAL:
        publicationTime = item.startTime ? getUnixTime(new Date(item.startTime as unknown as string)) : null;
        endPublicationTime = item.endTime ? getUnixTime(new Date(item.endTime as unknown as string)) : null;
        break;

      default:
        break;
    }

    const today = getUnixTime(DateTimeHelper.toUTC(new Date()));
    if ((!publicationTime && !endPublicationTime) || !publicationTime) {
      result = this.statusOptions.unpublished;
    } else if (publicationTime) {
      if (publicationTime <= today) {
        if (endPublicationTime && endPublicationTime <= today) {
          result = this.statusOptions.unpublished;
        } else if (endPublicationTime && endPublicationTime > today) {
          result = this.statusOptions.published;
        } else if (!endPublicationTime) {
          result = this.statusOptions.published;
        }
      } else if (publicationTime >= today) {
        result = this.statusOptions.scheduled;
      }
    }
    return result;
  }

  private onHoverItem(item: Data): void {
    this.itemHovered = item;
  }

  private isMissingTranslation(item: Data, entityType: EntityType): boolean {
    switch (entityType) {
      case EntityType.PRODUCT:
        return Product.hydrate(item)
          .isMissingTranslation(this.availableLocales, ['description', 'name']);
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        return LargeProduct.hydrate(item)
          .isMissingTranslation(this.availableLocales, ['description', 'name']);
      case EntityType.DEAL:
        return Deal.hydrate(item)
          .isMissingTranslation(this.availableLocales, ['content', 'title']);
      default:
        return true;
    }
  }

  private onMouseLeaveItem(): void {
    this.showMenu = false;
    this.itemHovered = null;
  }

  private onMenuItemClick(
    menuItem: { name: string; translation: string; icon: string },
    item: Data,
  ): void {
    switch (this.entityType) {
      case EntityType.PRODUCT:
        this.selectedItem = (item as unknown) as Product;
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        this.selectedItem = (item as unknown) as LargeProduct;
        break;
      case EntityType.HANDOUT:
      case EntityType.SPEAKER:
        this.selectedHandout = (item as unknown) as Handout;
        break;
      case EntityType.DEAL:
        this.selectedItem = (item as unknown) as Deal;
        break;
      case EntityType.USER:
        this.selectedItem = (item as unknown) as CommunityUser;
        break;
      case EntityType.EXHIBITOR_VIDEO:
        this.selectedItem = (item as unknown) as ExhibitorVideo;
        break;
      case EntityType.ORDER:
        this.selectedItem = item as unknown as Order;
        break;
      default:
        break;
    }
    let salesInvoiceUrl;
    switch (menuItem.name) {
      case 'preview':
        switch (this.entityType) {
          case EntityType.PRODUCT:
            this.$router.push({
              name: 'product-detail',
              params: { productId: item.uid as string },
            });
            break;
          case EntityType.LARGE_PRODUCT:
          case EntityType.BOAT_PRODUCT:
            this.$router.push({
              name: 'large-product-detail',
              params: { largeProductId: item.uid as string },
            });
            break;
          case EntityType.DEAL:
            this.$router.push({
              name: 'deal-detail',
              params: { dealId: item.uid as string },
            });
            break;
          case EntityType.HANDOUT:
          case EntityType.SPEAKER:
            if (this.selectedHandout
              && this.selectedHandout.handoutFileResource) {
              window.open(FileResourceHelper.getFullPath(
                this.selectedHandout.handoutFileResource as unknown as FileResource,
              ));
            }
            break;
          case EntityType.ORDER:
            this.$router.push({
              name: 'order-detail',
              params: {
                orderId: item.uid as string,
                companyId: this.adminPanelExhibitor.uid,
              },
            });
            break;
          default:
            break;
        }
        break;
      case 'edit':
        this.editItem();
        break;
      case 'publish':
        this.$bvModal.show('publish-modal-0');
        break;
      case 'unpublish':
        this.$bvModal.show('unpublish-modal-0');
        break;
      case 'delete':
        this.$bvModal.show('delete-modal-0');
        break;
      case 'copy-email':
        // eslint-disable-next-line no-case-declarations
        const emailToCopy = (this.selectedItem as CommunityUser)?.email;
        if (emailToCopy) {
          ClipboardHelper.copyTextToClipboard(emailToCopy.toString());
        }
        this.showToast(ToastActionType.CLIPBOARD_COPIED);
        break;
      case 'roles-standard':
        this.$bvModal.show('user-role-standard-modal-0');
        break;
      case 'roles-administrator':
        this.$bvModal.show('user-role-admin-modal-0');
        break;
      case 'permissions':
        this.$bvModal.show('permissions-manager-modal');
        break;
      case 'print':
        this.$router.push({
          name: 'sales-invoice',
          params: { orderId: item.uid as string },
        });
        salesInvoiceUrl = this.$router.resolve({
          name: 'sales-invoice',
          params: { orderId: item.uid as string },
        }).href;
        window.open(salesInvoiceUrl, '_blank');
        break;
      case 'play':
        this.showVideoPopup = true;
        break;
      default:
        break;
    }
  }

  private onHeaderMenuItemClick(
    menuItem: { name: string; translation: string; icon: string },
  ): void {
    this.selectedItem = null;
    this.selectedHandout = null;
    switch (menuItem.name) {
      case 'publish':
        this.$bvModal.show('publish-modal-main');
        break;
      case 'unpublish':
        this.$bvModal.show('unpublish-modal-main');
        break;
      case 'delete':
        this.$bvModal.show('delete-modal-main');
        break;
      default:
        break;
    }
  }

  private editItem(): void {
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
        this.$bvModal.show('product-modal');
        break;
      case EntityType.BOAT_PRODUCT:
        if (this.boatCanEditBooth || this.boatCanEdit) {
          this.$bvModal.show('boat-product-modal');
        }
        break;
      case EntityType.DEAL:
        this.$bvModal.show('promotion-modal');
        break;
      case EntityType.EXHIBITOR_VIDEO:
        this.$bvModal.show('exhibitor-video-modal');
        break;
      case EntityType.HANDOUT:
      case EntityType.SPEAKER:
        this.$bvModal.show('handout-modal');
        break;
      default:
        break;
    }
  }

  private deleteItem(): void {
    let item: Product | LargeProduct | Deal | Handout | Data | CommunityUser | null = null;
    switch (this.entityType) {
      case EntityType.EXHIBITOR_VIDEO:
        item = this.selectedItem;
        if (item) {
          this.$bvModal.hide('exhibitor-video-modal');
          this.offset = 0;
          this.deleteExhibitorVideo(item.uid.toString())
            .then((response) => {
              if (response) {
                this.loadBaseData();
                this.showToast(ToastActionType.DELETE_EXHIBITOR_VIDEO);
              }
            });
        }
        break;
      case EntityType.PRODUCT:
        item = this.selectedItem;
        if (item) {
          this.$bvModal.hide('product-modal');
          this.offset = 0;
          this.deleteProduct(item.uid.toString())
            .then((response) => {
              if (response) {
                this.loadBaseData();
                this.showToast(ToastActionType.DELETE_PRODUCT);
              }
            });
        }
        break;
      case EntityType.BOAT_PRODUCT:
      case EntityType.LARGE_PRODUCT:
        item = this.selectedItem;
        if (item) {
          this.$bvModal.hide('boat-product-modal');
          this.$bvModal.hide('product-modal');
          this.offset = 0;
          this.deleteLargeProduct(item.uid.toString())
            .then((response) => {
              if (response) {
                this.loadBaseData();
                this.showToast(ToastActionType.DELETE_PRODUCT);
              }
            });
        }
        break;
      case EntityType.DEAL:
        item = this.selectedItem;
        if (item) {
          this.$bvModal.hide('promotion-modal');
          this.offset = 0;
          this.deleteDeal(item.uid.toString())
            .then((response) => {
              if (response) {
                this.loadBaseData();
                this.showToast(ToastActionType.DELETE_DEAL);
              }
            });
        }
        break;
      case EntityType.HANDOUT:
      case EntityType.SPEAKER:
        item = this.selectedHandout;
        if (item) {
          this.$bvModal.hide('handout-modal');
          this.offset = 0;
          this.deleteHandout(item.uid.toString())
            .then((response) => {
              if (response) {
                this.loadBaseData();
                this.showToast(ToastActionType.DELETE_HANDOUT);
              }
            });
        }
        break;
      case EntityType.USER:
        item = (this.selectedItem as CommunityUser).roleInCompany;
        if (item) {
          this.offset = 0;
          const companyRole = item as CompanyUserRole;
          const roleUid = companyRole ? companyRole.uid.toString() : '';
          this.updateCompanyUserRoleState({
            uid: roleUid,
            state: companyRole.state === CompanyUserRoleStatusType.ACCEPTED
              ? CompanyUserRoleStatusType.CANCELLED
              : CompanyUserRoleStatusType.INVITE_CANCELLED,
          })
            .then((response) => {
              if (response) {
                this.showToast(ToastActionType.DELETE_COMPANY_USER_ROLE);
                this.loadBaseData();
              }
            });
        }
        break;
      default:
        break;
    }
    this.$bvModal.hide('delete-modal-0');
    this.selectedItem = null;
    this.selectedHandout = null;
  }

  private deleteItems(): void {
    const selectedIds = this.selectedList.map((selectedItem: Data) => selectedItem.uid.toString());
    switch (this.entityType) {
      case EntityType.EXHIBITOR_VIDEO:
        selectedIds.forEach((selectedItem: string) => {
          this.deleteExhibitorVideo(selectedItem)
            .then((response) => {
              if (response) {
                this.deletedItemsCount += 1;
              }
            });
        });
        break;
      case EntityType.PRODUCT:
        selectedIds.forEach((selectedItem: string) => {
          this.deleteProduct(selectedItem)
            .then((response) => {
              if (response) {
                this.deletedItemsCount += 1;
              }
            });
        });
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        selectedIds.forEach((selectedItem: string) => {
          this.deleteLargeProduct(selectedItem)
            .then((response) => {
              if (response) {
                this.deletedItemsCount += 1;
              }
            });
        });
        break;
      case EntityType.DEAL:
        selectedIds.forEach((selectedItem: string) => {
          this.deleteDeal(selectedItem)
            .then((response) => {
              if (response) {
                this.deletedItemsCount += 1;
              }
            });
        });
        break;
      case EntityType.HANDOUT:
      case EntityType.SPEAKER:
        selectedIds.forEach((selectedItem: string) => {
          this.deleteHandout(selectedItem)
            .then((response) => {
              if (response) {
                this.deletedItemsCount += 1;
              }
            });
        });
        break;
      case EntityType.USER:
        (this.selectedList as unknown as CommunityUser[]).forEach((selectedItem: CommunityUser) => {
          const companyRole = selectedItem.roleInCompany;
          const roleUid = companyRole ? companyRole.uid.toString() : '';
          this.updateCompanyUserRoleState({
            uid: roleUid,
            state: companyRole.state === CompanyUserRoleStatusType.ACCEPTED
              ? CompanyUserRoleStatusType.CANCELLED
              : CompanyUserRoleStatusType.INVITE_CANCELLED,
          })
            .then((response) => {
              if (response) {
                this.deletedItemsCount += 1;
              }
            });
        });
        break;
      default:
        break;
    }
    this.$bvModal.hide('delete-modal-main');
  }

  private publishItem(): void {
    const item = this.selectedItem;
    if (item) {
      switch (this.entityType) {
        case EntityType.PRODUCT:
          this.publishProduct({
            uid: item.uid.toString(),
            publicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                const updatedProduct = this.list.findIndex((product: Data) => product.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct,
                    1,
                    {
                      ...this.list[updatedProduct],
                      publicationTime: response.publicationTime as string,
                      endPublicationTime: response.endPublicationTime as string,
                    });
                }
                this.showToast(ToastActionType.PUBLISH_PRODUCT);
              }
            });
          break;
        case EntityType.LARGE_PRODUCT:
        case EntityType.BOAT_PRODUCT:
          this.publishLargeProduct({
            uid: item.uid.toString(),
            publicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                const updatedProduct = this.list
                  .findIndex((largeProduct: Data) => largeProduct.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    publicationTime: response.publicationTime as string,
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
                this.showToast(ToastActionType.PUBLISH_PRODUCT);
              }
            });
          break;
        case EntityType.DEAL:
          this.publishDeal({
            uid: item.uid.toString(),
            startTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                const updatedDeal = this.list.findIndex((deal: Data) => deal.uid === response.uid);
                if (updatedDeal > -1) {
                  this.list.splice(updatedDeal, 1, {
                    ...this.list[updatedDeal],
                    startTime: response.startTime as Date,
                    endTime: response.endTime as Date,
                  });
                }
                this.showToast(ToastActionType.PUBLISH_DEAL);
              }
            });
          break;
        default:
          break;
      }
    }
    this.$bvModal.hide('publish-modal-0');
    this.selectedItem = null;
  }

  // TODO: Refactor need to use publishItem method in publishItems
  private publishItems(): void {
    const selectedIds = this.selectedList.map((selectedItem: Data) => selectedItem.uid.toString());
    switch (this.entityType) {
      case EntityType.PRODUCT:
        selectedIds.forEach((selectedItem: string) => {
          this.publishProduct({
            uid: selectedItem,
            publicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                this.publishedItemsCount += 1;
                const updatedProduct = this.list.findIndex((product: Data) => product.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    publicationTime: response.publicationTime as string,
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
              }
            });
        });
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        selectedIds.forEach((selectedItem: string) => {
          this.publishLargeProduct({
            uid: selectedItem,
            publicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                this.publishedItemsCount += 1;
                const updatedProduct = this.list.findIndex((largeProduct: Data) => largeProduct.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    publicationTime: response.publicationTime as string,
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
              }
            });
        });
        break;
      case EntityType.DEAL:
        selectedIds.forEach((selectedItem: string) => {
          this.publishDeal({
            uid: selectedItem,
            startTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                this.publishedItemsCount += 1;
                const updatedDeal = this.list.findIndex((deal: Data) => deal.uid === response.uid);
                if (updatedDeal > -1) {
                  this.list.splice(updatedDeal, 1, {
                    ...this.list[updatedDeal],
                    startTime: response.startTime as Date,
                    endTime: response.endTime as Date,
                  });
                }
              }
            });
        });
        break;
      default:
        break;
    }
    this.$bvModal.hide('publish-modal-main');
  }

  private unpublishItem(): void {
    const item = this.selectedItem;
    if (item) {
      switch (this.entityType) {
        case EntityType.PRODUCT:
          this.unpublishProduct({
            uid: item.uid.toString(),
            endPublicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                const updatedProduct = this.list.findIndex((product: Data) => product.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
                this.showToast(ToastActionType.UNPUBLISH_PRODUCT);
              }
            });
          break;
        case EntityType.LARGE_PRODUCT:
        case EntityType.BOAT_PRODUCT:
          this.unpublishLargeProduct({
            uid: item.uid.toString(),
            endPublicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                const updatedProduct = this.list.findIndex((largeProduct: Data) => largeProduct.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
                this.showToast(ToastActionType.UNPUBLISH_PRODUCT);
              }
            });
          break;
        case EntityType.DEAL:
          this.unpublishDeal({
            uid: item.uid.toString(),
            endTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                const updatedDeal = this.list.findIndex((deal: Data) => deal.uid === response.uid);
                if (updatedDeal > -1) {
                  this.list.splice(updatedDeal, 1, {
                    ...this.list[updatedDeal],
                    endTime: response.endTime as Date,
                  });
                }
                this.showToast(ToastActionType.UNPUBLISH_DEAL);
              }
            });
          break;
        default:
          break;
      }
    }
    this.$bvModal.hide('unpublish-modal-0');
    this.selectedItem = null;
  }

  // TODO: Refactor need to use unpublishItem method in unpublishItems
  private unpublishItems(): void {
    const selectedIds = this.selectedList.map((selectedItem: Data) => selectedItem.uid.toString());
    switch (this.entityType) {
      case EntityType.PRODUCT:
        selectedIds.forEach((selectedItem: string) => {
          this.unpublishProduct({
            uid: selectedItem,
            endPublicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                this.unpublishedItemsCount += 1;
                const updatedProduct = this.list.findIndex((product: Data) => product.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
              }
            });
        });
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        selectedIds.forEach((selectedItem: string) => {
          this.unpublishLargeProduct({
            uid: selectedItem,
            endPublicationTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                this.unpublishedItemsCount += 1;
                const updatedProduct = this.list.findIndex((largeProduct: Data) => largeProduct.uid === response.uid);
                if (updatedProduct > -1) {
                  this.list.splice(updatedProduct, 1, {
                    ...this.list[updatedProduct],
                    endPublicationTime: response.endPublicationTime as string,
                  });
                }
              }
            });
        });
        break;
      case EntityType.DEAL:
        selectedIds.forEach((selectedItem: string) => {
          this.unpublishDeal({
            uid: selectedItem,
            endTime: format(
              DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime()),
              DateTimeHelper.TIME_FORMAT_ISO_8601,
            ),
          })
            .then((response) => {
              if (response) {
                this.unpublishedItemsCount += 1;
                const updatedDeal = this.list.findIndex((deal: Data) => deal.uid === response.uid);
                if (updatedDeal > -1) {
                  this.list.splice(updatedDeal, 1, {
                    ...this.list[updatedDeal],
                    endTime: response.endTime as Date,
                  });
                }
              }
            });
        });
        break;
      default:
        break;
    }
    this.$bvModal.hide('unpublish-modal-main');
  }

  private onAddItemClick(): void {
    this.selectedItem = null;
    this.selectedHandout = null;
    switch (this.entityType) {
      case EntityType.PRODUCT:
      case EntityType.LARGE_PRODUCT:
        this.$bvModal.show('product-modal');
        break;
      case EntityType.BOAT_PRODUCT:
        if (this.boatCanCreate) {
          this.$bvModal.show('boat-product-modal');
        }
        break;
      case EntityType.DEAL:
        this.$bvModal.show('promotion-modal');
        break;
      case EntityType.HANDOUT:
      case EntityType.SPEAKER:
        this.$bvModal.show('handout-modal');
        break;
      case EntityType.USER:
        this.$bvModal.show('member-modal');
        break;
      case EntityType.EXHIBITOR_VIDEO:
        this.$bvModal.show('exhibitor-video-modal');
        break;
      default:
        break;
    }
  }

  private onHeaderItemClick(header: { name: string }): void {
    if (header.name === 'Name') {
      this.orderBy = this.orderBy === 'name_asc' ? 'name_desc' : 'name_asc';
      this.loadBaseData();
    }
  }

  private onDeleteByModal(): void {
    this.$bvModal.show('delete-modal-0');
  }

  private getItemImage(item: Data): string | null {
    let result = null;
    let product = null;
    let largeProduct = null;
    let deal = null;
    let handout = null;
    switch (this.entityType) {
      case EntityType.PRODUCT:
        product = (item as unknown) as Product;
        if (product.images && product.images.length > 0) {
          result = FileResourceHelper.getFullPath(product.images[0].fullFileResource, 'w32');
        }
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        largeProduct = (item as unknown) as LargeProduct;
        if (largeProduct.images && largeProduct.images.length > 0) {
          result = FileResourceHelper.getFullPath(largeProduct.images[0].fullFileResource, 'w32');
        }
        break;
      case EntityType.DEAL:
        deal = (item as unknown) as Deal;
        if (deal.displayFileResource) {
          result = FileResourceHelper.getFullPath(deal.displayFileResource, 'w32');
        }
        break;
      case EntityType.HANDOUT:
      case EntityType.SPEAKER:
        handout = (item as unknown) as Handout;
        if (handout.thumbnailFileResource) {
          result = FileResourceHelper.getFullPath(handout.thumbnailFileResource, 'w32');
        }
        break;
      case EntityType.EXHIBITOR_VIDEO:
        handout = (item as unknown) as Handout;
        if (handout.thumbnailFileResource) {
          result = FileResourceHelper.getFullPath(handout.thumbnailFileResource, 'w32');
        }
        break;
      default:
        break;
    }

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  private getHandoutIcon(item: Data): {
    icon: string;
    iconClass: string;
  } {
    let result = {
      icon: '',
      iconClass: '',
    };
    const handout = (item as unknown) as Handout;
    if (handout) {
      switch (handout.handoutFileResource?.fileType) {
        case FileType.PDF:
          result = {
            icon: 'fa-file-pdf',
            iconClass: 'text-red-r-3-primary-red',
          };
          break;
        case FileType.PPT:
          result = {
            icon: 'fa-file-chart-pie',
            iconClass: 'text-yellow-y-3-primary-yellow',
          };
          break;
        case FileType.DOC:
          result = {
            icon: 'fa-file-lines',
            iconClass: 'text-blue-b-3-primary-blue',
          };
          break;
        case FileType.XLS:
          result = {
            icon: 'fa-file-spreadsheet',
            iconClass: 'text-green-g-3-primary-green',
          };
          break;
        default:
          break;
      }
    }

    return result;
  }

  @Watch('publishedItemsCount')
  private updatePublishedItemsCount(): void {
    if (this.publishedItemsCount > 0 && this.selectedList.length === this.publishedItemsCount) {
      this.publishedItemsCount = 0;
      this.selectedList = [];
      switch (this.entityType) {
        case EntityType.PRODUCT:
        case EntityType.LARGE_PRODUCT:
        case EntityType.BOAT_PRODUCT:
          this.showToast(ToastActionType.PUBLISH_PRODUCT);
          break;
        case EntityType.DEAL:
          this.showToast(ToastActionType.PUBLISH_DEAL);
          break;
        default:
          break;
      }
    }
  }

  @Watch('unpublishedItemsCount')
  private updateUnpublishedItemsCount(): void {
    if (this.unpublishedItemsCount > 0 && this.selectedList.length === this.unpublishedItemsCount) {
      this.unpublishedItemsCount = 0;
      this.selectedList = [];
      switch (this.entityType) {
        case EntityType.PRODUCT:
        case EntityType.LARGE_PRODUCT:
        case EntityType.BOAT_PRODUCT:
          this.showToast(ToastActionType.UNPUBLISH_PRODUCT);
          break;
        case EntityType.DEAL:
          this.showToast(ToastActionType.UNPUBLISH_DEAL);
          break;
        default:
          break;
      }
    }
  }

  @Watch('deletedItemsCount')
  private updateDeletedItemsCount(): void {
    if (this.deletedItemsCount > 0 && this.selectedList.length === this.deletedItemsCount) {
      this.loadBaseData();
      this.deletedItemsCount = 0;
      this.selectedList = [];
      this.showToast(ToastActionType.DELETE_SELECTION);
    }
  }

  private itemSaved(): void {
    this.selectedItem = null;
    if (this.entityType === EntityType.USER) {
      this.$bvModal.hide('member-modal-0');
    }
    if (this.entityType !== EntityType.PRODUCT
      && this.entityType !== EntityType.LARGE_PRODUCT
      && this.entityType !== EntityType.BOAT_PRODUCT) {
      this.doCount = true;
      this.loadBaseData();
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  @Watch('productPromisesLoading')
  @Watch('promotionLinksLoading')
  private onProductPromisesLoading(): void {
    if (!this.productPromisesLoading && !this.promotionLinksLoading) {
      this.doCount = true;
      this.loadBaseData();
    }
  }

  @Watch('largeProductPromisesLoading')
  private onLargeProductPromisesLoading(): void {
    if (!this.largeProductPromisesLoading && !this.promotionLinksLoading) {
      this.doCount = true;
      this.loadBaseData();
    }
  }

  @Watch('promotionLinksLoading')
  private onPromotionLinksLoading(): void {
    if (!this.promotionLinksLoading && !this.largeProductPromisesLoading && !this.productPromisesLoading) {
      this.doCount = true;
      this.loadBaseData();
    }
  }

  @Watch('saveLoading')
  private onCompanyUserRolePromisesLoading(): void {
    if (!this.saveLoading) {
      this.doCount = true;
      this.loadBaseData();
    }
  }

  private updateUserCompanyRole(role: string): void {
    const item = (this.selectedItem as CommunityUser).roleInCompany;
    if (this.selectedItem && item) {
      this.getRoleByCode(role)
        .then((responseRole) => {
          if (responseRole && item) {
            this.updateCompanyUserRole({
              companyUserRoleId: item.uid,
              roleId: responseRole.uid,
            })
              .then((response) => {
                if (response) {
                  if (this.authUser && this.authUser.companyRoles) {
                    const found = this.authUser.companyRoles.find((c) => c.uid === response.uid);
                    if (found) {
                      found.role.name = response.role.name;
                      found.role.code = response.role.code;
                    }
                  }
                  const userIndex = this.list.findIndex((user: Data) => {
                    const companyUserRoleData = (user as unknown as CommunityUser).roleInCompany;
                    return companyUserRoleData
                      ? companyUserRoleData.uid === response.uid
                      : false;
                  });
                  if (userIndex > -1) {
                    const userToUpdate = (this.list[userIndex] as unknown) as CommunityUser;
                    if (userToUpdate && userToUpdate.roleInCompany) {
                      userToUpdate.roleInCompany.role.name = response.role.name;
                      userToUpdate.roleInCompany.role.code = response.role.code;
                      this.list.splice(userIndex, 1, (userToUpdate as unknown) as Data);
                    }
                  }
                  this.$bvModal.hide('user-role-standard-modal-0');
                  this.$bvModal.hide('user-role-admin-modal-0');
                  this.showToast(ToastActionType.UPDATE_COMPANY_USER_ROLE);
                }
              });
          }
        });
    }
  }

  private companyUserRoleSetPermissions(payload: {
    companyUserRoleId: string;
    moderator: boolean;
    canPost: boolean;
    handleMeetings: boolean;
    manageSales: boolean;
    manageContent: boolean;
    manageCompanyUserRoles: boolean;
  }): void {
    const item = (this.selectedItem as CommunityUser).roleInCompany;
    if (this.selectedItem && item) {
      const userIndex = this.list.findIndex((user: Data) => {
        const companyUserRoleData = (user as unknown as CommunityUser).roleInCompany;
        return companyUserRoleData
          ? companyUserRoleData.uid === payload.companyUserRoleId
          : false;
      });
      if (userIndex > -1) {
        const userToUpdate = this.list[userIndex] as unknown as CommunityUser;
        if (userToUpdate && userToUpdate.roleInCompany) {
          userToUpdate.roleInCompany.moderator = payload.moderator;
          userToUpdate.roleInCompany.canPost = payload.canPost;
          userToUpdate.roleInCompany.manageSales = payload.manageSales;
          userToUpdate.roleInCompany.manageContent = payload.manageContent;
          userToUpdate.roleInCompany.manageCompanyUserRoles = payload.manageCompanyUserRoles;
          userToUpdate.roleInCompany.handleMeetings = payload.handleMeetings;
          this.list.splice(userIndex, 1, userToUpdate as unknown as Data);
        }
      }
    }
  }

  @Watch('adminPanelExhibitor')
  private hasDefaultLimit(): boolean {
    if (this.isNewLimitCalculationFeatureEnabled) {
      return false;
    }
    switch (this.entityType) {
      case EntityType.PRODUCT:
        if (this.adminPanelExhibitor._effectiveLimitNumProducts === -1) {
          this.nbToAddLeft = -1;
          return false;
        }
        this.nbToAddLeft = this.adminPanelExhibitor._effectiveLimitNumProducts
          ? this.adminPanelExhibitor._effectiveLimitNumProducts - this.totalCount
          : -1;
        this.totalAuthorized = this.adminPanelExhibitor._effectiveLimitNumProducts;
        return this.adminPanelExhibitor._effectiveLimitNumProducts !== undefined
          && this.adminPanelExhibitor._effectiveLimitNumProducts > -1;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        if (this.adminPanelExhibitor._effectiveLimitNumLargeProducts === -1) {
          this.nbToAddLeft = -1;
          return false;
        }
        this.nbToAddLeft = this.adminPanelExhibitor._effectiveLimitNumLargeProducts
          ? this.adminPanelExhibitor._effectiveLimitNumLargeProducts - this.totalCount
          : -1;
        this.totalAuthorized = this.adminPanelExhibitor._effectiveLimitNumLargeProducts;
        return this.adminPanelExhibitor._effectiveLimitNumLargeProducts !== undefined
          && this.adminPanelExhibitor._effectiveLimitNumLargeProducts > -1;
      case EntityType.DEAL:
        if (this.adminPanelExhibitor._effectiveLimitNumDeals === -1) {
          this.nbToAddLeft = -1;
          return false;
        }
        this.nbToAddLeft = this.adminPanelExhibitor._effectiveLimitNumDeals
          ? this.adminPanelExhibitor._effectiveLimitNumDeals - this.totalCount
          : -1;
        this.totalAuthorized = this.adminPanelExhibitor._effectiveLimitNumDeals;
        return this.adminPanelExhibitor._effectiveLimitNumDeals !== undefined
          && this.adminPanelExhibitor._effectiveLimitNumDeals > -1;
      case EntityType.HANDOUT:
        if (this.adminPanelExhibitor._effectiveLimitNumHandouts === -1) {
          this.nbToAddLeft = -1;
          return false;
        }
        this.nbToAddLeft = this.adminPanelExhibitor._effectiveLimitNumHandouts
          ? this.adminPanelExhibitor._effectiveLimitNumHandouts - this.totalCount
          : -1;
        this.totalAuthorized = this.adminPanelExhibitor._effectiveLimitNumHandouts;
        return this.adminPanelExhibitor._effectiveLimitNumHandouts !== undefined
          && this.adminPanelExhibitor._effectiveLimitNumHandouts > -1;
      case EntityType.USER:
        if (this.adminPanelExhibitor._effectiveLimitNumCompanyRoles === -1) {
          this.nbToAddLeft = -1;
          return false;
        }
        this.nbToAddLeft = this.adminPanelExhibitor._effectiveLimitNumCompanyRoles
          ? this.adminPanelExhibitor._effectiveLimitNumCompanyRoles - this.totalCount
          : -1;
        this.totalAuthorized = this.adminPanelExhibitor._effectiveLimitNumCompanyRoles;
        return this.adminPanelExhibitor._effectiveLimitNumCompanyRoles !== undefined
          && this.adminPanelExhibitor._effectiveLimitNumCompanyRoles > -1;
      default:
        return false;
    }
  }

  private filterSlotLimits(): void {
    let slotType = null;
    switch (this.entityType) {
      case EntityType.USER:
        this.loadGenericFeatureSlot();
        slotType = SlotTypeEnum.TEAM_MEMBER_SLOT;
        break;
      case EntityType.PRODUCT:
        slotType = SlotTypeEnum.PRODUCT_SLOT;
        this.filterSubEntitySlot(SlotTypeEnum.PRODUCT_HANDOUT_SLOT);
        break;
      case EntityType.LARGE_PRODUCT:
      case EntityType.BOAT_PRODUCT:
        slotType = SlotTypeEnum.LARGE_PRODUCT_SLOT;
        this.filterSubEntitySlot(SlotTypeEnum.LARGE_PRODUCT_HANDOUT_SLOT);
        break;
      case EntityType.EXHIBITOR_VIDEO:
        slotType = SlotTypeEnum.VIDEO_SLOT;
        break;
      case EntityType.HANDOUT:
        slotType = SlotTypeEnum.HANDOUT_SLOT;
        break;
      case EntityType.DEAL:
        slotType = SlotTypeEnum.SHOW_DEAL_SLOT;
        break;
      default:
        break;
    }
    if (slotType) {
      let filter = null;
      if (this.handoutSubType) {
        filter = {
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: slotType,
          type: this.handoutSubType,
        };
      } else {
        filter = {
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: slotType,
        };
      }
      if (filter) {
        this.filterSlots(filter)
          .then((response) => {
            let limit = -1;
            if (response && response.length) {
              const limits = response.map((slot) => slot.limit);
              if (limits.indexOf(-1) === -1) {
                limit = Math.max(...limits);
              }
            }
            this.totalAuthorized = limit;
            if (limit === -1) {
              this.nbToAddLeft = limit;
              return;
            }
            if (this.totalCount > limit) {
              this.nbToAddLeft = 0;
            } else {
              this.nbToAddLeft = limit - this.totalCount;
            }
          });
      }
    }
  }

  private loadGenericFeatureSlot(): void {
    this.filterSlots({
      exhibitorUid: this.adminPanelExhibitor.uid,
      code: SlotTypeEnum.MEETING_FEATURE,
    })
      .then((response) => {
        if (response && response[0]) {
          this.isMeetingFeatureEnabled = response[0].limit === -1 || response[0].limit > 0;
        } else {
          this.isMeetingFeatureEnabled = false;
        }
      });
    this.filterSlots({
      exhibitorUid: this.adminPanelExhibitor.uid,
      code: SlotTypeEnum.MESSAGE_FEATURE,
    })
      .then((response) => {
        if (response && response[0]) {
          this.isMessageFeatureEnabled = response[0].limit === -1 || response[0].limit > 0;
        } else {
          this.isMessageFeatureEnabled = false;
        }
      });
  }

  private loadBoatSlots(): void {
    if (this.entityType === EntityType.BOAT_PRODUCT) {
      Promise.all([
        this.filterSlots({
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: SlotTypeEnum.BOAT_CAN_CREATE_SLOT,
        })
          .then((response) => {
            this.boatCanCreate = response && response.length > 0;
          }),
        this.filterSlots({
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: SlotTypeEnum.BOAT_CAN_EDIT_SLOT,
        })
          .then((response) => {
            this.boatCanEdit = response && response.length > 0;
          }),
        this.filterSlots({
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: SlotTypeEnum.BOAT_CAN_EDIT_BOOTH_SLOT,
        })
          .then((response) => {
            this.boatCanEditBooth = response && response.length > 0;
          }),
        this.filterSlots({
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: SlotTypeEnum.BOAT_CAN_IMPORT_FROM_NMMA_SLOT,
        })
          .then((response) => {
            this.boatCanImportFromNMMA = response && response.length > 0;
          }),
        this.filterSlots({
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: SlotTypeEnum.BOAT_FIELD_SLOT,
        })
          .then((response) => {
            if (response && response.length > 0) {
              this.boatFields = response.map((slot) => slot.type);
            }
          }),
        this.filterSlots({
          exhibitorUid: this.adminPanelExhibitor.uid,
          code: SlotTypeEnum.BOAT_EXTRA_PROPERTY_SLOT,
        })
          .then((response) => {
            if (response && response.length > 0) {
              this.boatExtraPropertyKeys = response.map((slot) => slot.type);
            }
          }),
      ])
        .then(() => {
          if (this.boatExtraPropertyKeys.length > 0) {
            this.loadExtraPropertySections({
              filter: {
                configsInSection: {
                  key_in: this.boatExtraPropertyKeys,
                },
              },
              params: {
                filter: ` filter: { key_in: [${this.boatExtraPropertyKeys.map((val) => `"${val}"`)
                  .join(', ')}] }`,
              },
            })
              .then((sections) => {
                this.extraPropertySections = sections;
              });
          }
        });
    }
  }

  private filterSubEntitySlot(code: string): void {
    this.filterSlots({
      exhibitorUid: this.adminPanelExhibitor.uid,
      code,
    })
      .then((response) => {
        let limit = -1;
        if (response && response.length) {
          const limits = response.map((slot) => slot.limit);
          if (limits.indexOf(-1) === -1) {
            limit = Math.max(...limits);
          }
        }
        this.entityHandoutLimit = limit;
      });
  }

  private onViewUpgradesClick(): void {
    this.$router.push({
      name: 'cms-package-list',
      params: { companyId: this.adminPanelExhibitor.uid },
    });
  }
}
